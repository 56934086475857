import * as React from "react"
import H1 from "../utils/H1";
import H2 from "../utils/H2";
import H3 from "../utils/H3"
import H5 from "../utils/H5";
import Button from "../utils/Button";
import { Link } from "gatsby"
import { StaticImage } from "gatsby-plugin-image"
import BannerSlider from "../components/BannerSlider";
import Layout from "../components/layout"
import Seo from "../components/seo"
import Paragraph from "../utils/Paragraph";
import SetTile from "../components/common/SetTile";
import NewsTile from "../components/common/NewsTile";

import Group2 from "../images/Group2.jpeg"
import Group3 from "../images/Group3.jpeg"
import { StaticQuery, graphql } from "gatsby"

const SetsApart = [
  {
    "description": "We have an extensive network of partners and investors to utilize based on our companies’ needs",
    "image": "/images/setIcon1.svg"
  },
  {
    "description": "We are focused and committed to our companies",
    "image": "/images/Group8.svg"
  },
  {
    "description": "We are passionate about aiding in the growth and success of our companies",
    "image": "/images/Group6.svg"
  },
]
const Index = (props) => (
  <StaticQuery
    query={graphql`
      query {
          allWpPortfolio {
              nodes {
                title
                portfolio {
                  description
                  link
                }
                featuredImage {
                  node {
                    mediaItemUrl
                  }
                }
                excerpt
              }
          }
        }
      `}

    render={data => (
      <Layout>
        <Seo title="Home" />
        <div className="lg:grid lg:grid-cols-2 lg:grid-rows-1 flex flex-col lg:gap-12 min-h-bannerDesktop">
          <div className="lg:hidden h-300px bg-home bg-bottom bg-cover bg-no-repeat bg-cover relative z-0">
            <div className="overlay absolute w-full h-full top-0 left-0 opacity-80 z-0">

            </div>
            <div className="absolute w-full h-full flex flex-col justify-center items-center z-0">
              {/* <H1 className="text-white bg-none">Home</H1> */}
              <H1 className="mb-4 heading2white reverse text-center md:w-10/12 w-full">The road to endless opportunities awaits.</H1>
              <H3 bottom="mb-10 text-orange">Let us guide the way.</H3>
            </div>
          </div>
          <div className="flex flex-col lg:mt-0 lg:mb-0 mb-8 mt-4 py-6 pr-4 w-full h-full justify-center lg:pl-12 px-5%">
            <H1 className="mb-4 heading1 lg:block hidden">The road to endless opportunities awaits.</H1>
            <H3 bottom="mt-4 text-orange lg:block hidden">Let us guide the way.</H3>
            <Paragraph className="text-grayText">We are an investment and development company that is committed to unleashing the full potential of start-ups and industry disruptors. By providing essential guidance, capital, and other advisory services to companies in seed and early stages, we are able to aid in their growth and success.</Paragraph>
            {/* <h2 className="heading2white font-lato font-bold text-4xl">Multi-Industry Focus</h2> */}
            <H3 bottom="mt-4 text-orange lg:block">Multi-Industry Focus</H3>
            <Paragraph className="text-grayText">We at Flying Point Industries have decades of experience with growing businesses. Our expertise spans across multiple industries and allows us to cater our services to the individual needs of our partners. We are particularly well-versed in financial, technological and healthcare market.</Paragraph>
            <Button type={"internalLink"} to="/our-story" className="text-white bg-blue mt-12 mr-auto ml-0">Read More</Button>
          </div>
          <div className="h-auto min-h-bannerDesktop w-full lg:block hidden">
            <BannerSlider></BannerSlider>
          </div>

        </div>
        <div className="bg-pattern bg-opacity-85 bg-contain bg-no-repeat px-5% py-20 bg-lightblue w-full flex flex-col justify-center items-left">
          <H2 className="lg:text-left text-center">What Sets Us Apart</H2>
          <div className="grid lg:grid-cols-3 lg:grid-rows-1 lg:gap-12 grid-cols-1 auto-rows-auto gap-y-12 mt-12">
            {SetsApart.map((row, index) => (
              <SetTile description={row.description} image={row.image} key={"set" + index}></SetTile>
            ))}
          </div>
        </div>
        {/* <div className="bg-gourmazeBlack bg-honeycomb bg-center bg-fixed bg-no-repeat bg-contain px-5% py-20 w-full flex flex-col justify-center items-left">
          <div className="grid grid-cols-2 grid-rows-1 gap-40">
            <div className="w-full">
              <img src="/images/banner2.png" alt="" className="object-cover w-full rounded-xl" />
            </div>
            <div className="w-full flex flex-col justify-center items-left">
              <h2 className="heading2white font-lato font-bold text-4xl">Multi-Industry Focus</h2>
              <Paragraph className="text-white mt-12">We at Flying Point Industries have decades of experience with growing businesses. Our expertise spans across multiple industries and allows us to cater our services to the individual needs of our partners. We are particularly well-versed in financial, technological and healthcare market.</Paragraph>
              <Button type={"internalLink"} to="/about-us" className={"bg-orange button-shadow-orange mt-12 text-white ml-0 mr-auto w-auto"}>Read More</Button>
            </div>
          </div>
        </div> */}
        {/* <div className="px-5% py-20 bg-white w-full flex flex-col justify-center items-center">
          <H2 className="lg:text-left text-center">A Selection of Our Portfolio Companies</H2>

          <div className="grid grid-cols-1 auto-rows-auto lg:gap-y-8 gap-y-12 md:mt-20 mt-12 w-full">
            {data.allWpPortfolio.nodes.map((row, index) => (
              index < 3 ?
                <NewsTile portfolio={true} data={row} key={index}></NewsTile> : ''
            ))}
          </div>
          <Button type="internalLink" to="/news" className="bg-white text-blue border-1 border-blue mt-12 hover:bg-blue hover:text-white ml-auto mr-auto">View More</Button>

        </div> */}
        <div className="bg-white px-5% py-20 w-full flex flex-col justify-center items-left">
          <H2 className="lg:text-center text-center w-full">Recent Event Sponsorships</H2>
          <div className="grid lg:grid-cols-2 lg:grid-rows-1 grid-rows-2 grid-cols-1 lg:gap-24 gap-y-8 mt-16 lg:w-10/12 w-full ml-auto mr-auto">
            <a href="https://bschool.pepperdine.edu/institutes-centers/institutes/peate-institute-for-entrepreneurship/most-fundable-companies/2018-list/" target="_blank" className="rounded-xl bg-lightblue transform hover:-translate-y-2 transition-all duration-300 ease-in-out group">
              <div className="bg-lightblue lg:p-8 p-4 rounded-xl flex flex-col justify-center items-center overflow-hidden">
                <img src={"/images/sponsor1.jpg"} alt="" className="md:mb-12 mb-4 md:h-300px w-full transform group-hover:scale-105 transition-all duration-300 ease-in-out" />
                <H5 className="text-blue text-center lg:h-12" >Pepperdine Most Fundable Companies Showcase</H5>
                {/* <H5 className="text-grayText text-center mt-2 w-9/12 m-auto" >October 14th, 2020 And October 17th, 2019 Malibu, CA</H5> */}
              </div>
            </a>
            <a href="https://us.money2020.com/companies/flying-point-industries-venture-capital" target="_blank" className="rounded-xl transform hover:-translate-y-2 transition-all duration-300 ease-in-out group">
              <div className="bg-lightblue lg:p-8 p-4 rounded-xl flex flex-col justify-center items-center">
                <img src="/images/money2020.jpg" alt="" className="md:h-300px md:mb-12 mb-4 transform group-hover:scale-105 transition-all duration-300 ease-in-out" />
                <H5 className="text-blue text-center lg:h-12">Money 20/20 Conference</H5>
                {/* <H5 className="text-grayText text-center mt-2 w-9/12 m-auto" >October 15th, 2020 And October 17th, 2019 Malibu, CA</H5> */}
              </div>
            </a>
          </div>
        </div>
      </Layout>
    )}
  />

)
export default Index
