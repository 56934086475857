import React from 'react'
import Paragraph from '../../utils/Paragraph'
import Button from '../../utils/Button'
import H3 from '../../utils/H3'
export default function CompanyTile(props) {
    return (
        <a href={props.data.portfolio.link} target="_blank">
            <div className="client-block box p-5 flex flex-col border-1 cursor-pointer border-white hover:border-borderGrey rounded-xl transition-all duration-300 ease-in-out relative overflow-hidden group">
                <img src={props.data.featuredImage.node.mediaItemUrl} className="mb-5 md:w-28 w-20 filter group-hover:filter-none transition-all duration-300 ease-in-out "></img>
                <H3 className={"text-blue font-bold mt-2 mb-4 md:max-w-80%"}>{props.data.title}</H3>
                <div className={"md:w-10/12 text-base text-textGray"} dangerouslySetInnerHTML={{ __html: props.data.portfolio.description }}></div>
                {
                    props.portfolio ?
                        <Button className="text-sm ml-auto bg-orange text-white button-shadow-orange md:mt-0 mt-8" type={"external"} portfolio={props.portfolio}>read more</Button>
                        : ""}
                <img src="/images/download-1.png" className="client-bg w-52 filter grayscale group-hover:filter-none"></img>
            </div>
        </a>
    )
}